<template>

    <TablePage ref="TablePage" main-url="/nsi-requests" @tableItemsClick="tableItemsClick">

        <template #toolBarRightSide>
            <Button class="p-button p-button-outlined mr-3" label="Запросить описание сведений НСИ"
                    @click="requestNsi('meta')"/>
            <Button class="p-button p-button-outlined mr-3" label="Запросить исторические сведения НСИ"
                    @click="requestNsi('history')"/>
            <Button class="p-button p-button-outlined" label="Запросить актуальный справочник НСИ"
                    @click="requestNsi('actual')"/>

        </template>

        <template #tableColumns="TableProps">

            <Column :sortable="true" field="time_created">
                <template #header>
                    <SearchDate v-model="TableProps.filters['time_created']" label="Контейнер отправлен"/>
                </template>
                <template #body="slotProps">
                    {{ unixTimeStampToDateTime(slotProps.data?.['time_created']) }}
                </template>
            </Column>

            <Column :sortable="true" style="width:15%">
                <template #header>
                    <SearchSelect v-model="TableProps.filters['delivery_status']"
                                  :options="deliveryStatuses"
                                  label="Статус"
                                  option-label="key"
                                  option-value="value"/>
                </template>
                <template #body="slotProps">
                        <span>{{
                                getObjectFromArrayByPropertyValue(deliveryStatuses, 'value', slotProps.data.delivery_status).key || 'Статус неизвестен'
                            }}</span>
                </template>
            </Column>
            <Column :sortable="true" field="request_guid" filter-field="request_guid">
                <template #header>
                    <SearchInput v-model="TableProps.filters['request_guid']" label="Идентификатор запроса"/>
                </template>
            </Column>
            <Column :sortable="true" field="container_guid" filter-field="container_guid">
                <template #header>
                    <SearchInput v-model="TableProps.filters['container_guid']" label="Идентификатор контейнера"/>
                </template>
            </Column>
            <Column :sortable="true" field="type" filter-field="type">
                <template #header>
                    <SearchInput v-model="TableProps.filters['type']" label="Тип запроса"/>
                </template>
            </Column>

        </template>

    </TablePage>

    <RequestCard ref="RequestCard" :deliveryStatuses="deliveryStatuses" page-url="nsi-requests"/>

</template>

<script>
import {DataFilters, Helper, SearchDate, SearchInput, SearchSelect, TablePage} from "@xnpmpackages/xcomponents";
import RequestCard from "@/components/app/sppr_requests/RequestCard";

export default {
    name: "NsiRequests",
    components: {RequestCard, SearchInput, SearchSelect, TablePage, SearchDate},
    mixins: [Helper, DataFilters],
    data() {
        return {

            deliveryStatuses: [
                {key: 'Отправлен', value: 0},
                {key: 'Принят организатором', value: 1},
                {key: 'Не принят организатором', value: 2},
                {key: 'Предоставлен полный ответ', value: 3},
                {key: 'Предоставлен частичный ответ', value: 4},
                {key: 'В предоставлении данных отказано', value: 5},
                {key: 'Все', value: null}]

        }
    },
    methods: {

        async tableItemsClick($event) {

            await this.$refs.RequestCard.show(this.copyObjectByJSON($event.data), this.tableItems);

        },

        async requestNsi($referenceType) {

            this.$confirm.require({
                message: 'Организатору будет отправлен контейнер с запросом на получение данных. Продолжить?',
                header: 'Подтверждение',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Да',
                rejectLabel: 'Нет',
                accept: async () => {

                    try {

                        this.$xapi.showBlockModal('Формирование контейнера...');

                        await this.$xapi.post(
                            '/nsi-requests',
                            {
                                action: 'requestNsi',
                                referenceType: $referenceType,
                            },
                        );

                        this.$xapi.xnotify('Организатору отправлен запрос');
                        this.$refs.TablePage.getTableRows(true);

                    } finally {
                        this.$xapi.hideBlockModal();
                    }

                },
                reject: async () => {
                    this.$xapi.hideBlockModal();
                },

            });

        },

    },
    mounted() {
        //this.$refs.TablePage.getTableRows(true);
    }
}
</script>

<style scoped>

</style>